<template functional>
  <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.29625 2.38006L10.62 3.70318L9.29625 2.38006ZM10.1475 1.21443L6.56813 4.79381C6.38318 4.9785 6.25705 5.2138 6.20563 5.47006L5.875 7.12506L7.53 6.79381C7.78625 6.74256 8.02125 6.61693 8.20625 6.43193L11.7856 2.85256C11.8932 2.745 11.9785 2.61731 12.0367 2.47677C12.0949 2.33624 12.1249 2.18561 12.1249 2.0335C12.1249 1.88138 12.0949 1.73076 12.0367 1.59022C11.9785 1.44969 11.8932 1.322 11.7856 1.21443C11.6781 1.10687 11.5504 1.02155 11.4098 0.96334C11.2693 0.905129 11.1187 0.875168 10.9666 0.875168C10.8144 0.875168 10.6638 0.905129 10.5233 0.96334C10.3828 1.02155 10.2551 1.10687 10.1475 1.21443V1.21443Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10.875 8.375V10.25C10.875 10.5815 10.7433 10.8995 10.5089 11.1339C10.2745 11.3683 9.95652 11.5 9.625 11.5H2.75C2.41848 11.5 2.10054 11.3683 1.86612 11.1339C1.6317 10.8995 1.5 10.5815 1.5 10.25V3.375C1.5 3.04348 1.6317 2.72554 1.86612 2.49112C2.10054 2.2567 2.41848 2.125 2.75 2.125H4.625" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'IconEdit'
}
</script>

<style>
</style>
